import { named, withDependencies } from '@wix/thunderbolt-ioc'
import { FeatureExportsSymbol, SiteFeatureConfigSymbol } from '@wix/thunderbolt-symbols'
import { IFeatureExportsStore } from 'thunderbolt-feature-exports'
import { IRoutingConfig } from '.'
import { name } from './symbols'
import { IPagesMap } from './types'

const pagesMap = (routingConfig: IRoutingConfig, routerExports: IFeatureExportsStore<typeof name>): IPagesMap => {
	const getPageById: IPagesMap['getPageById'] = (pageId) => routingConfig.pagesMap[pageId]

	routerExports.export({ getPageById })

	return { getPageById }
}

export const PagesMap = withDependencies(
	[named(SiteFeatureConfigSymbol, name), named(FeatureExportsSymbol, name)],
	pagesMap
)
